import Send from '../../user.client.js'

export default {
    getBusinessNumber (param) {
        return Send({
            url: '/user/proxy/bizno',
            method: 'get',
            params: {
                bzrgNM: param.bzrgNM,
                bzrgNo: param.bzrgNo
            }
        })
    }
}
